import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import Page from '@/components/Page';
import { DEFAULT_PAGE } from '@/constants/table';
import { useFetchKeywordGroup } from '@/hooks/queries/keywords';
import { useUrlParams } from '@/hooks/urlParams';
import getRoute from '@/utils/getRoute';

import Error from '../../misc/error';
import Results from './_partials/Results';
import Toolbar from './_partials/Toolbar';

const useStyles = makeStyles()(() => ({
  resultsBox: {
    marginTop: '16px'
  }
}));

const defaultParams = {
  search: ''
};

const Keywords = () => {
  const { classes } = useStyles();
  const id = Number(useParams().id);

  const urlParams = useUrlParams(getRoute.keywords.DETAIL(), defaultParams);

  const [selectedKeywords, setSelectedKeywords] = useState([]);

  const {
    data: keywordGroup = {},
    isLoading,
    isError
  } = useFetchKeywordGroup(id);

  const handleChangeSearch = ({ target: { value } }) => {
    urlParams.updateParams({ search: value, page: DEFAULT_PAGE });
  };

  const keywordsList = keywordGroup?.keywords?.filter((data) =>
    data.value.toLowerCase().includes(urlParams.params.search?.toLowerCase())
  );

  const { search } = urlParams.params;

  if (isError) return <Error />;

  return (
    <Page title="Keywords">
      <Toolbar
        id={id}
        keywordsLength={keywordGroup.keywords?.length}
        keywordsList={keywordsList}
        onChangeSearch={handleChangeSearch}
        selectedKeywords={selectedKeywords}
        title={keywordGroup?.title}
        searchValue={search ? String(search) : ''}
      />
      <Box className={classes.resultsBox}>
        <Results
          isLoading={isLoading}
          keywordsList={keywordsList}
          onSelectKeyword={setSelectedKeywords}
          selectedKeywords={selectedKeywords}
        />
      </Box>
    </Page>
  );
};

export default Keywords;
