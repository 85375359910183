import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import {
  CalendarToday,
  Description,
  PeopleAlt,
  Refresh,
  History
} from '@mui/icons-material';
import { Container, Divider, Skeleton, Stack, Typography } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { makeStyles } from 'tss-react/mui';

import Button from '@/components/button';
import GenericHeader from '@/components/header';
import {
  GROUP_ID,
  PRODUCTION_DATASET_ID,
  STAGING_DATASET_ID
} from '@/constants/powerbi';
import { useFetchEvaluation } from '@/hooks/queries/evaluations';
import {
  useGenerateEmbededToken,
  useGetPowerBIapiToken,
  useGetRefreshHistoryInGroup,
  useGetReportInGroup,
  useRefreshDatasetInGroup
} from '@/hooks/queries/powerbi';
import { useAppSelector } from '@/hooks/redux';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Evaluation } from '@/models/evaluations';
import LocalStorageService from '@/services/LocalStorageService';
import { getDateWithTime, getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';

const useStyles = makeStyles()(() => ({
  reportClass: {
    height: '700px',
    width: '100%'
  }
}));

const REPORT_ID = process.env.REACT_APP_POWERBI_REPORT_ID as string;
const DATASET_ID = process.env.REACT_APP_POWERBI_DATASET_ID as string;

const EvaluationDashboard = () => {
  const { classes } = useStyles();
  const navigate = useWorkspaceNavigate();
  const evaluationId = Number(useParams().evaluationId);
  const user = useAppSelector((state) => state.user.user);
  const queryClient = useQueryClient();
  const alert = useAlert();

  const evaluationPreviousData = queryClient.getQueryData([
    'evaluation',
    evaluationId
  ]) as Evaluation.Details;

  const [accessReportToken, setAcessReportToken] = useState({
    token: '',
    expiration: ''
  });

  const { data: evaluation, isLoading: isEvaluationLoading } =
    useFetchEvaluation({
      id: evaluationId,
      enabled: !evaluationPreviousData
    });

  const { data: powerBIapi } = useGetPowerBIapiToken({
    onSuccess: (data) => {
      LocalStorageService.setItem('powerbi-token', data.access_token);
      LocalStorageService.setItem('powerbi-token-expires-in', data.expires_in);
    }
  });

  const { data: reportData } = useGetReportInGroup({
    groupId: GROUP_ID,
    reportId: REPORT_ID,
    enabled: !!powerBIapi
  });

  const { data: refreshHistory } = useGetRefreshHistoryInGroup({
    groupId: GROUP_ID,
    datasetId: DATASET_ID,
    top: 1,
    enabled: !!powerBIapi
  });

  const { mutate: generateEmbededToken } = useGenerateEmbededToken({
    onSuccess: (response) => {
      setAcessReportToken({
        token: response.data.token,
        expiration: response.data.expiration
      });
      setTimeout(() => {
        initiateTokenGeneration();
      }, new Date(response.data.expiration).getTime() - Date.now());
    }
  });

  const { mutate: refreshDataset, isLoading: isRefreshing } =
    useRefreshDatasetInGroup({
      groupId: GROUP_ID,
      datasetId: DATASET_ID,
      onSuccess: () => {
        alert.success('Dataset refreshed successfully');
        queryClient.invalidateQueries({
          queryKey: ['getRefreshHistory', GROUP_ID, DATASET_ID]
        });
      },
      onError: ({ response }) => {
        if (
          response.status === 400 &&
          response.data.error.code === 'InvalidRequest'
        ) {
          alert.error(response.data.error.message);
        } else if (response.status === 429) {
          alert.error(`You can't refresh more. Please try again later`);
        }
      }
    });

  const filters = [
    `posts_view/workspace_id eq ${Number(user?.workspaceId)}`,
    `posts_view/evaluation_id eq ${evaluationId}`,
    `evaluations_view/workspace_id eq ${Number(user?.workspaceId)}`
  ];

  const powerBIReportURL = useMemo(
    () => `${reportData?.embedUrl}&filter=${filters.join(' and ')}`,
    [evaluationId, user, reportData]
  );

  const initiateTokenGeneration = () => {
    generateEmbededToken({
      datasets: [{ id: STAGING_DATASET_ID }, { id: PRODUCTION_DATASET_ID }],
      reports: [{ id: REPORT_ID }]
    });
  };

  useEffect(() => {
    // Make sure we have the power api token first
    if (powerBIapi) initiateTokenGeneration();
  }, [powerBIapi]);

  interface IconTextProps {
    itemIcon: JSX.Element;
    text: string;
    textColor?: string;
    loading?: boolean;
  }

  const Item = ({
    itemIcon,
    text,
    textColor = 'textSecondary',
    loading = false
  }: IconTextProps) => (
    <Stack direction="row" gap={1}>
      {itemIcon}
      {loading ? (
        <Skeleton variant="rectangular" width={100}></Skeleton>
      ) : (
        <Typography color={textColor}>{text}</Typography>
      )}
    </Stack>
  );

  return (
    <Container>
      <GenericHeader
        breadcrumbsList={[
          {
            name: 'Evaluations',
            onClick: () =>
              navigate(getPathWithLocalParams(getRoute.evaluations.LIST()))
          },
          {
            name: evaluationPreviousData?.title || evaluation?.title,
            onClick: () =>
              navigate(
                getPathWithLocalParams(
                  getRoute.evaluations.DETAIL(evaluationId)
                )
              )
          }
        ]}
        onClickReturn={() =>
          navigate(
            getPathWithLocalParams(getRoute.evaluations.DETAIL(evaluationId))
          )
        }
        title="Dashboard"
      />

      <Stack
        marginTop={3}
        marginBottom={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" gap={1}>
          <Item
            loading={isEvaluationLoading}
            itemIcon={<CalendarToday fontSize="small" color="disabled" />}
            text={`${getPrettyDate(
              evaluationPreviousData?.parameters?.startCreationDate ||
                evaluation?.parameters?.startCreationDate
            )} - ${getPrettyDate(
              evaluationPreviousData?.parameters?.endCreationDate ||
                evaluation?.parameters?.endCreationDate
            )}`}
          />
          <Divider flexItem orientation="vertical" />
          <Item
            loading={isEvaluationLoading}
            itemIcon={<PeopleAlt fontSize="small" color="disabled" />}
            text={`${
              evaluationPreviousData?.totalCreators || evaluation?.totalCreators
            } Creators`}
          />
          <Divider flexItem orientation="vertical" />
          <Item
            loading={isEvaluationLoading}
            itemIcon={<Description fontSize="small" color="disabled" />}
            text={`${
              evaluationPreviousData?.totalItems || evaluation?.totalItems
            } Items`}
          />
        </Stack>
        <Stack alignItems="center" direction="row" gap={3}>
          <Stack direction="row" gap={1} alignItems="center">
            <History />
            <Typography>Last refresh:</Typography>
            <Typography color="textSecondary">
              {refreshHistory?.value[0]?.startTime ? (
                getDateWithTime(refreshHistory?.value[0]?.startTime)
              ) : (
                <Skeleton variant="rectangular" width={175}></Skeleton>
              )}
            </Typography>
          </Stack>
          <Button
            startIcon={<Refresh />}
            color="inherit"
            text="refresh"
            loading={isRefreshing}
            onClick={() => {
              refreshDataset({ notifyOption: '' });
            }}
          />
        </Stack>
      </Stack>

      {!reportData?.embedUrl || !accessReportToken ? (
        <Skeleton variant="rectangular" className={classes.reportClass} />
      ) : (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: REPORT_ID,
            embedUrl: powerBIReportURL,
            accessToken: accessReportToken.token,
            tokenType: models.TokenType.Embed,
            datasetBinding: {
              datasetId: DATASET_ID
            },
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                },
                pageNavigation: {
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent
            }
          }}
          cssClassName={classes.reportClass}
        />
      )}
    </Container>
  );
};

export default EvaluationDashboard;
