import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Container, Skeleton, Typography } from '@mui/material';
import {
  GridRowSelectionModel,
  GridSortDirection,
  GridSortModel
} from '@mui/x-data-grid';

import Page from '@/components/Page';
import Avatar from '@/components/avatar/Avatar';
import Button from '@/components/button/Button';
import DataTable from '@/components/dataTable/DataTable';
import ChipsColumn from '@/components/dataTable/customChipsColumn';
import Header from '@/components/header/Header';
import Toolbar from '@/components/toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useFetchCreatorGroup } from '@/hooks/queries/creatorGroups';
import { getPathWithLocalParams, useUrlParams } from '@/hooks/urlParams';
import { useDebounce } from '@/hooks/useDebounce';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { URLParams } from '@/models/misc';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';
import nFormatter from '@/utils/nFormatter/nFormatter';
import Error from '@/views/misc/error';

import AddCreator from './_partials/AddCreatorToGroup';
import RemoveCreator from './_partials/RemoveCreatorFromGroup';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: undefined
};

const CreatorGroupsDetail = () => {
  const id = Number(useParams().id);
  const navigate = useWorkspaceNavigate();

  const urlParams = useUrlParams(
    getRoute.creatorGroups.DETAIL(id, defaultParams),
    defaultParams
  );

  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [isOpenAddCreatorDialog, setIsOpenAddCreatorDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const deferredGroupsValue = useDebounce(urlParams.params.name, 500);

  const {
    data: group,
    isLoading: isGroupLoading,
    isError
  } = useFetchCreatorGroup({
    id,
    params: {
      ...urlParams.params,
      name: deferredGroupsValue ? String(deferredGroupsValue) : undefined
    }
  });

  const handleChangePageSize = useCallback(
    (newSize: string) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const handleChangePage = useCallback(
    (newPage: string) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const handleSortModelChange = useCallback(
    (params: GridSortModel) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      urlParams.updateParams({
        sort: newSort,
        order: (newOrder?.toUpperCase() as URLParams['order']) || undefined
      });
    },
    [urlParams]
  );

  const handleChangeSearch = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      urlParams.updateParams({ name: value, page: DEFAULT_PAGE });
    },
    [urlParams]
  );

  const columns = useMemo(
    () => [
      {
        sortable: false,
        field: 's3AvatarPath',
        headerName: 'Avatar',
        renderCell: (params: any) => {
          const { name, avatarUrl } = params.row;

          return (
            <Box paddingLeft={1}>
              <Avatar src={avatarUrl} name={name} width="40px" fontSize={16} />
            </Box>
          );
        }
      },
      {
        sortable: false,
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        flex: 1,
        renderCell: (params: any) => {
          const { name } = params.row;

          return (
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </Typography>
          );
        }
      },
      {
        sortable: false,
        field: 'platform',
        headerName: 'Platform',
        minWidth: 150,
        flex: 1,
        renderCell: (params: any) => {
          const { platforms } = params.row;

          const transformedArray = Object.entries(platforms)
            .filter(([, value]) => value === true)
            .map(([key]) => ({
              tooltip: '',
              keyword: key.charAt(0).toUpperCase() + key.slice(1)
            }));

          return (
            <ChipsColumn
              data={transformedArray}
              chipColor="default"
              cellWidth={params.colDef.computedWidth}
            />
          );
        }
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        flex: 1,
        width: 140,
        renderCell: (params: any) => getPrettyDate(params.row.createdAt)
      },
      {
        field: 'addedAt',
        headerName: 'Added',
        flex: 1,
        width: 140,
        renderCell: (params: any) => getPrettyDate(params.row.addedAt)
      },
      {
        sortable: false,
        field: 'contentCount',
        headerName: 'Content Count',
        minWidth: 100,
        flex: 1,
        renderCell: (params: any) => {
          const { contentCount } = params.row;

          return <Typography>{nFormatter(contentCount)}</Typography>;
        }
      }
    ],
    [group?.results]
  );

  const { sort, order, page, size, name } = urlParams.params;

  if (isError) return <Error />;

  return (
    <>
      <Page title="Creator Group">
        <Container>
          <Header
            actions={
              <Button
                onClick={() => setIsOpenAddCreatorDialog(true)}
                text="Add Creators"
              />
            }
            onClickReturn={() =>
              navigate(getPathWithLocalParams(getRoute.creatorGroups.LIST()))
            }
            breadcrumbsList={[
              {
                name: 'Creator Groups',
                onClick: () =>
                  navigate(
                    getPathWithLocalParams(getRoute.creatorGroups.LIST())
                  )
              }
            ]}
            title={
              isGroupLoading ? (
                <Skeleton variant="text" width="200px" />
              ) : (
                group?.name
              )
            }
          />
          <Toolbar
            isDeleteEnabled={selected.length > 0}
            onClickDelete={() => setIsOpenDeleteDialog(true)}
            onChangeSearch={handleChangeSearch}
            showDeleteButton
            showSearchInput
            searchValue={name ? String(name) : ''}
            totalItems={group?.totalItems || 0}
          />
          <DataTable
            rowSelectionModel={selected}
            onRowSelectionModelChange={setSelected}
            onRowClick={(row) => navigate(getRoute.creators.DETAIL(row.id))}
            rowHeight={80}
            rows={group?.results || []}
            rowCount={group?.totalItems || 0}
            columns={columns}
            loading={isGroupLoading}
            checkboxSelection={true}
            paginationMode="server"
            filterMode="server"
            sortingMode="server"
            onChangePageSize={handleChangePageSize}
            onChangePage={handleChangePage}
            onSortModelChange={handleSortModelChange}
            sort={sort}
            order={order?.toLowerCase() as GridSortDirection}
            page={page}
            size={size}
          />
        </Container>
      </Page>
      {isOpenAddCreatorDialog && (
        <AddCreator
          isOpen={isOpenAddCreatorDialog}
          onClose={() => {
            setIsOpenAddCreatorDialog(false);
          }}
        />
      )}
      {isOpenDeleteDialog && (
        <RemoveCreator
          isOpen={isOpenDeleteDialog}
          onClose={() => setIsOpenDeleteDialog(false)}
          selected={selected}
          clearSelection={() => {
            setSelected([]);
          }}
          creatorsList={group?.results || []}
        />
      )}
    </>
  );
};

export default CreatorGroupsDetail;
