import { forwardRef } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Breakpoint, Container, Paper, Stack } from '@mui/material';

interface Props {
  children: JSX.Element;
  title?: string;
  className?: string;
  header?: JSX.Element;
  toolbar?: JSX.Element;
  maxWidth?: Breakpoint | false;
}

const Page = forwardRef(
  (
    {
      children,
      title = '',
      className,
      header,
      toolbar,
      maxWidth = 'xl',
      ...rest
    }: Props,
    ref
  ) => (
    <Box ref={ref} className={className} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {header && (
        <Paper
          elevation={0}
          sx={{ pt: '31px', mb: '33px', px: '24px' }} // Add px for horizontal padding
        >
          <Container maxWidth={maxWidth} sx={{ px: 0 }}>
            {header}
            {toolbar}
          </Container>
        </Paper>
      )}
      <Stack px="24px">
        <Container maxWidth={maxWidth}>{children}</Container>
      </Stack>
    </Box>
  )
);

Page.displayName = 'Page';

export default Page;
