import { useState } from 'react';

import { DeleteOutlined, Search } from '@mui/icons-material';
import {
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Stack
} from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { deleteKeywords } from '@/api/keywords';
import Button from '@/components/button';
import Card from '@/components/card';
import Dialog from '@/components/dialog';
import Header from '@/components/header';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute';

import AddKeywordToGroup from './AddKeywordToGroup';

const Toolbar = ({
  id,
  onChangeSearch,
  keywordsList,
  keywordsLength,
  title,
  selectedKeywords,
  searchValue
}) => {
  const navigate = useWorkspaceNavigate();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isKeywordsDialogOpen, setKeywordsDialogOpen] = useState(false);

  const handleConfirmDeleteKeyword = () => {
    const keywordsToDelete = keywordsList
      .filter((keyword) => selectedKeywords.includes(keyword.id))
      .map((keyword) => keyword.value);

    const data = {
      values: keywordsToDelete.join(','),
      categoryId: id
    };

    deleteKeywords(data)
      .then(() => {
        const alertMessage =
          selectedKeywords.length === 1
            ? `"${keywordsToDelete[0]}" has been successfully deleted`
            : `${selectedKeywords.length} keywords have been successfully deleted`;

        alert.info(alertMessage);
        queryClient.invalidateQueries({
          queryKey: ['keywordGroups'],
          refetchType: 'all'
        });
      })
      .catch(() =>
        alert.error(`Some error occurred while deleting the keywords`)
      )
      .finally(() => {
        setDeleteDialogOpen(false);
      });
  };

  return (
    <>
      <Stack>
        <Header
          sx={{ pt: 3 }}
          mb
          actions={
            <Button
              onClick={() => setKeywordsDialogOpen(true)}
              text="Add keyword"
            />
          }
          onClickReturn={() =>
            navigate(getPathWithLocalParams(getRoute.keywords.LIST()))
          }
          breadcrumbsList={[
            {
              name: 'Keyword Group',
              onClick: () =>
                navigate(getPathWithLocalParams(getRoute.keywords.LIST()))
            }
          ]}
          title={title}
        />
        <Card
          content={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>
                {keywordsList?.length === keywordsLength
                  ? `Total: ${keywordsList?.length}`
                  : `Total: ${keywordsList?.length} of ${keywordsLength}`}
              </Typography>
              <Stack direction="row" alignItems="center">
                <IconButton
                  disabled={selectedKeywords?.length <= 0}
                  onClick={() => setDeleteDialogOpen(true)}
                  size="large"
                  style={{
                    margin: '0px 5px'
                  }}
                >
                  <DeleteOutlined
                    color={
                      selectedKeywords?.length <= 0 ? 'disabled' : 'action'
                    }
                  />
                </IconButton>
                <TextField
                  id="input-with-icon-textfield"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  label="Search"
                  onChange={(e) => onChangeSearch(e)}
                  value={searchValue}
                  size="small"
                  variant="outlined"
                />
              </Stack>
            </Stack>
          }
        />
      </Stack>
      <Dialog
        title="Delete Keyword"
        dialogContent={
          selectedKeywords.length === 1 ? (
            <>
              <Typography>
                Are you sure you want to delete &ldquo;
                {
                  keywordsList.find((item) => item.id === selectedKeywords[0])
                    ?.value
                }
                &ldquo;?
              </Typography>
              <Typography>
                You will not be able to recover this keyword.
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Are you sure you want to delete {selectedKeywords.length}{' '}
                keywords?
              </Typography>
              <Typography>You will not be able to recover these.</Typography>
            </>
          )
        }
        open={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        actions={
          <>
            <Button
              onClick={() => setDeleteDialogOpen(false)}
              variant="text"
              text="cancel"
            />
            <Button
              onClick={handleConfirmDeleteKeyword}
              color="error"
              text="delete"
            />
          </>
        }
      />

      {isKeywordsDialogOpen && (
        <AddKeywordToGroup
          isOpen={isKeywordsDialogOpen}
          onClose={() => setKeywordsDialogOpen(false)}
        />
      )}
    </>
  );
};

Toolbar.propTypes = {
  id: PropTypes.number,
  onChangeSearch: PropTypes.func,
  keywordsList: PropTypes.array,
  keywordsLength: PropTypes.number,
  title: PropTypes.string,
  selectedKeywords: PropTypes.array,
  searchValue: PropTypes.string
};

export default Toolbar;
