import { useMutation, useQuery } from '@tanstack/react-query';

import {
  generateEmbededToken,
  generateTokenInGroup,
  getPowerBIapiToken,
  getRefreshHistoryInGroup,
  getReportInGroup,
  refreshDatasetInGroup
} from '@/api/powerbi';
import { PowerBI } from '@/models/powerbi';

type onSuccessType = (data: any) => void;
type onErrorType = (error: any) => void;

type PowerBIProps = {
  groupId: string;
  reportId: string;
  enabled?: boolean;
  onSuccess?: onSuccessType;
  onError?: onErrorType;
};

export const useGenerateTokenInGroup = ({
  groupId,
  reportId,
  onSuccess,
  onError
}: PowerBIProps) =>
  useMutation({
    mutationKey: ['generateTokenInGroup', groupId, reportId],
    mutationFn: (payload: PowerBI.GenerateTokenInGroupPayload) =>
      generateTokenInGroup(groupId, reportId, payload),
    onSuccess,
    onError
  });

export const useGenerateEmbededToken = ({
  onSuccess,
  onError
}: {
  onSuccess?: onSuccessType;
  onError?: onErrorType;
}) =>
  useMutation({
    mutationKey: ['generateEmbededToken'],
    mutationFn: (payload: PowerBI.GenerateEmbedTokenPayload) =>
      generateEmbededToken(payload),
    onSuccess,
    onError
  });

export const useGetReportInGroup = ({
  groupId,
  reportId,
  onSuccess,
  onError,
  enabled
}: PowerBIProps) =>
  useQuery({
    queryKey: ['getReportInGroup', groupId, reportId],
    queryFn: async () => {
      const result = await getReportInGroup(groupId, reportId);
      return result.data;
    },
    onSuccess,
    onError,
    enabled
  });

type GetPowerBIapiTokenProps = {
  onSuccess?: onSuccessType;
  onError?: onErrorType;
};

export const useGetPowerBIapiToken = ({
  onSuccess,
  onError
}: GetPowerBIapiTokenProps) =>
  useQuery({
    queryKey: ['getPowerBIapiToken'],
    queryFn: async () => {
      const result = await getPowerBIapiToken();
      return result.data;
    },
    onSuccess,
    onError
  });

type RefreshDatasetInGroup = {
  groupId: string;
  datasetId: string;
  top?: number;
  enabled?: boolean;
  onSuccess?: onSuccessType;
  onError?: onErrorType;
};

export const useRefreshDatasetInGroup = ({
  groupId,
  datasetId,
  onSuccess,
  onError
}: RefreshDatasetInGroup) =>
  useMutation({
    mutationKey: ['refreshDatasetInGroup', groupId, datasetId],
    mutationFn: (payload: any) =>
      refreshDatasetInGroup(datasetId, groupId, payload),
    onSuccess,
    onError
  });

export const useGetRefreshHistoryInGroup = ({
  groupId,
  datasetId,
  top = 60,
  onSuccess,
  onError,
  enabled
}: RefreshDatasetInGroup) =>
  useQuery({
    queryKey: ['getRefreshHistory', groupId, datasetId],
    queryFn: async () => {
      const result = await getRefreshHistoryInGroup(datasetId, groupId, top);
      return result.data;
    },
    onSuccess,
    onError,
    enabled
  });
