import { UnknownObject } from '@/models/misc';

import { decomposeParams, api } from '.';

export const getEvaluations = (params: UnknownObject = {}) =>
  api.get(`v2/evaluations${decomposeParams(params)}`);

export const getEvaluation = (id: number) => api.get(`v2/evaluations/${id}`);

export const createEvaluation = (payload: UnknownObject) =>
  api.post(`v2/evaluations`, payload);

export const updateEvaluation = (id: number, payload: UnknownObject) =>
  api.put(`v2/evaluations/${id}`, payload);

export const deleteEvaluation = (payload: UnknownObject) =>
  api.delete(`v2/evaluations`, { data: payload });

export const previewEvaluation = (params: UnknownObject) =>
  api.post(`v2/evaluations/preview`, params);

export const evaluationTitleExists = (title: string) =>
  api.get(`filters/title/${encodeURIComponent(title)}`);

export const deleteEvaluationPosts = (payload: UnknownObject) =>
  api.delete(`v2/evaluations/posts`, { data: payload });

export const getEvaluationReport = (
  id: number,
  params: UnknownObject,
  payload: UnknownObject
) => api.post(`v2/evaluations/report/${id}${decomposeParams(params)}`, payload);
