import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Skeleton } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import Page from '@/components/Page';
import Button from '@/components/button/Button';
import Header from '@/components/header/Header';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import {
  useDeleteCreator,
  useFetchCreator,
  useFetchCreatorsPosts,
  useUpdateCreator
} from '@/hooks/queries/creators';
import { getPathWithLocalParams, useUrlParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute/getRoute';
import Error from '@/views/misc/error';

import DeleteDialog from '../delete/DeleteCreator';
import EditDialog from '../edit/EditCreator';
import Accounts from './_partials/Accounts';
import AddCreatorToGroups from './_partials/AddCreatorToGroups';
import MergeCreators from './_partials/MergeCreators';
import Profile from './_partials/Profile';
import Results from './_partials/Results';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE
};

const CreatorDetail = () => {
  const { id } = useParams();
  const navigate = useWorkspaceNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();
  const urlParams = useUrlParams(
    getRoute.creators.DETAIL(Number(id), defaultParams),
    defaultParams
  );

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAddToGroupsDialogOpen, setIsAddToGroupsDialogOpen] = useState(false);
  const [isMergeCreatorsDialogOpen, setIsMergeCreatorsDialogOpen] =
    useState(false);

  const {
    data: creator,
    isLoading: isLoadingCreator,
    isError: isErrorCreator
  } = useFetchCreator({ id: Number(id) });

  const { data: creatorPosts, isLoading: isLoadingCreatorPosts } =
    useFetchCreatorsPosts({
      id: Number(id),
      params: { ...urlParams.params }
    });

  const { mutate: deleteCreator, isLoading: isDeleting } = useDeleteCreator({
    onSuccess: () => {
      alert.success(`${creator.name} was successfully deleted!`);
      queryClient.invalidateQueries({
        queryKey: ['posts'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creators'],
        refetchType: 'all'
      });
      navigate(getRoute.creators.LIST());
    },
    onError: () => alert.error(`Some error occurred while deleting the creator`)
  });

  const { mutate: updateCreator, isLoading: isUpdating } = useUpdateCreator({
    id: Number(id),
    onSuccess: () => {
      alert.success(`Creator successfully updated!`);
      queryClient.invalidateQueries({
        queryKey: ['creator', Number(id)],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['posts'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creatorAvatars', Number(id)],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creatorsPosts', Number(id)],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creators'],
        refetchType: 'all'
      });
      setIsEditDialogOpen(false);
    },
    onError: () => alert.error(`Some error occurred while updating the creator`)
  });

  const handleClickDelete = () => {
    setIsEditDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  if (isErrorCreator) return <Error />;

  return (
    <Page title={creator?.name || 'Creator detail page'}>
      <>
        <Header
          sx={{ pt: 3 }}
          mb
          actions={
            <Button
              onClick={() => setIsAddToGroupsDialogOpen(true)}
              startIcon={<Add />}
              text="Add to group"
            />
          }
          breadcrumbsList={[
            {
              name: 'Creators',
              onClick: () =>
                navigate(getPathWithLocalParams(getRoute.creators.LIST()))
            }
          ]}
          onClickReturn={() =>
            navigate(getPathWithLocalParams(getRoute.creators.LIST()))
          }
          title={
            isLoadingCreator ? (
              <Skeleton variant="text" width="200px" />
            ) : (
              creator?.name
            )
          }
        />
        <Profile
          name={creator?.name}
          avatarUrl={creator?.avatarUrl}
          creationDate={creator?.creationDate}
          showSkeleton={isLoadingCreator}
          onClickEdit={() => setIsEditDialogOpen(true)}
          onClickMerge={() => setIsMergeCreatorsDialogOpen(true)}
        />
        <Accounts accounts={creator?.accounts} />
        <Results
          items={creatorPosts?.results || []}
          isLoading={isLoadingCreatorPosts}
          totalItems={creatorPosts?.totalItems || 0}
        />
        {isEditDialogOpen && (
          <EditDialog
            creator={creator}
            isOpen={isEditDialogOpen}
            onClose={() => {
              queryClient.invalidateQueries({
                queryKey: ['creatorAvatars', Number(id)],
                refetchType: 'all'
              });
              setIsEditDialogOpen(false);
            }}
            onSubmit={(data) => updateCreator(data)}
            onClickDelete={handleClickDelete}
            isUpdating={isUpdating}
          />
        )}
        {isMergeCreatorsDialogOpen && (
          <MergeCreators
            creator={creator}
            isOpen={isMergeCreatorsDialogOpen}
            onClose={() => setIsMergeCreatorsDialogOpen(false)}
          />
        )}
        {isAddToGroupsDialogOpen && (
          <AddCreatorToGroups
            isOpen={isAddToGroupsDialogOpen}
            onClose={() => setIsAddToGroupsDialogOpen(false)}
          />
        )}
        {isDeleteDialogOpen && (
          <DeleteDialog
            name={creator?.name}
            isOpen={isDeleteDialogOpen}
            onClose={() => {
              setIsEditDialogOpen(true);
              setIsDeleteDialogOpen(false);
            }}
            onSubmit={() => deleteCreator({ ids: [Number(id)] })}
            isDeleting={isDeleting}
          />
        )}
      </>
    </Page>
  );
};

export default CreatorDetail;
