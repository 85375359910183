import React, { FC } from 'react';

import {
  Checkbox as MaterialCheckbox,
  FormControlLabel,
  CheckboxProps as MaterialCheckboxProps
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

interface CheckboxProps {
  value?: unknown;
  label?: string | JSX.Element;
  variant?: MaterialCheckboxProps['color'];
  isChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  indeterminate?: boolean;
  disabled?: MaterialCheckboxProps['disabled'];
}

const useStyle = makeStyles()({
  formControlLabel: {
    margin: 0
  }
});

const Checkbox: FC<CheckboxProps> = ({
  value,
  label,
  variant = 'primary',
  isChecked,
  onChange,
  indeterminate,
  disabled
}) => {
  const { classes } = useStyle();

  return (
    <FormControlLabel
      classes={{ root: classes.formControlLabel }}
      control={
        <MaterialCheckbox
          disabled={disabled}
          value={value}
          color={variant}
          checked={isChecked}
          onChange={onChange}
          indeterminate={indeterminate}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
