import { useParams } from 'react-router-dom';

import Page from '@/components/Page';
import Loading from '@/components/loading';
import { useFetchEvaluation } from '@/hooks/queries/evaluations';
import Error from '@/views/misc/error';

import Body from './body/Body';
import Header from './header';

const EvaluationDetails = () => {
  const evaluationId = Number(useParams().evaluationId);

  const { isLoading, isError, isFetching } = useFetchEvaluation({
    id: evaluationId
  });

  if (isError) return <Error />;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page title="Evaluation Details">
      <>
        <Header isFetching={isFetching} />
        <Body />
      </>
    </Page>
  );
};

export default EvaluationDetails;
