import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import GenericHeader from '@/components/header';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute/getRoute';

import EvaluationCriteria from './_partials/EvaluationCriteria';

const Header = ({ isFetching }) => {
  const evaluationId = Number(useParams().evaluationId);
  const navigate = useWorkspaceNavigate();

  const queryClient = useQueryClient();
  const { title } = queryClient.getQueryData(['evaluation', evaluationId]);

  return (
    <>
      <GenericHeader
        sx={{ pt: 3 }}
        breadcrumbsList={[
          {
            name: 'Evaluations',
            onClick: () =>
              navigate(getPathWithLocalParams(getRoute.evaluations.LIST()))
          }
        ]}
        onClickReturn={() =>
          navigate(getPathWithLocalParams(getRoute.evaluations.LIST()))
        }
        title={title}
      />
      <Box mt={3}>
        <EvaluationCriteria isFetching={isFetching} />
      </Box>
    </>
  );
};

Header.propTypes = {
  isFetching: PropTypes.bool
};

export default Header;
