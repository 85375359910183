import { Container } from '@mui/material';

import Header from '@/components/header/Header';

const Sandbox = () => (
  <Container>
    <Header title="Sandbox" />
  </Container>
);

export default Sandbox;
