import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createEvaluation,
  deleteEvaluation,
  getEvaluations,
  getEvaluation,
  previewEvaluation,
  updateEvaluation,
  evaluationTitleExists,
  deleteEvaluationPosts,
  getEvaluationReport
} from '@/api/evaluations';
import { UnknownObject } from '@/models/misc';

import { Callback } from '.';

type onSuccessType = (data: any) => void;
type onErrorType = (error: any) => void;

type FetchEvaluations = {
  onSuccess: Callback;
  onError: Callback;
  refetchInterval?: number;
};

export const useFetchEvaluations = ({
  onSuccess,
  onError,
  refetchInterval
}: FetchEvaluations) =>
  useQuery({
    queryKey: ['evaluations'],
    queryFn: async () => {
      const result = await getEvaluations();
      return result.data;
    },
    refetchInterval,
    onSuccess,
    onError
  });

type FetchEvaluation = {
  id: number;
  onSuccess?: Callback;
  onError?: Callback;
  refetchInterval?: number;
  enabled?: boolean;
};

export const useFetchEvaluation = ({
  id,
  onSuccess,
  onError,
  refetchInterval,
  enabled = true
}: FetchEvaluation) =>
  useQuery({
    queryKey: ['evaluation', id],
    queryFn: async () => {
      const result = await getEvaluation(id);
      return result.data;
    },
    refetchInterval,
    onSuccess,
    onError,
    enabled
  });

export const useCreateEvaluation = (onSuccess: Callback, onError: Callback) =>
  useMutation({
    mutationFn: createEvaluation,
    onSuccess,
    onError
  });

export const useUpdateEvaluation = (
  id: number,
  onSuccess: Callback,
  onError: Callback
) =>
  useMutation({
    mutationFn: (payload: UnknownObject) => updateEvaluation(id, payload),
    onSuccess,
    onError
  });

export const useDeleteEvaluation = (onSuccess: Callback, onError: Callback) =>
  useMutation({
    mutationFn: deleteEvaluation,
    onSuccess,
    onError
  });

export const usePreviewEvaluation = (onSuccess: Callback, onError: Callback) =>
  useMutation({ mutationFn: previewEvaluation, onSuccess, onError });

export const useEvaluationTitleExists = (
  onSuccess: Callback,
  onError: Callback
) => useMutation({ mutationFn: evaluationTitleExists, onSuccess, onError });

export const useDeleteEvaluationPosts = (
  onSuccess: Callback,
  onError: Callback
) =>
  useMutation({
    mutationFn: deleteEvaluationPosts,
    onSuccess,
    onError
  });

type EvaluationReportProps = {
  id: number;
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useGetEvaluationReport = ({
  id,
  onSuccess,
  onError
}: EvaluationReportProps) =>
  useMutation({
    mutationFn: (payload: UnknownObject) =>
      getEvaluationReport(id, payload.params, { postsIds: payload.postsIds }),
    onSuccess,
    onError
  });
