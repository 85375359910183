import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams, NavLink as RouterLink } from 'react-router-dom';

import {
  CheckCircleRounded,
  WarningAmber,
  Error,
  Warning
} from '@mui/icons-material';
import {
  Typography,
  Chip,
  Stack,
  Box,
  Link,
  Tooltip,
  LinearProgress,
  alpha
} from '@mui/material';
import {
  getGridSingleSelectOperators,
  getGridStringOperators
} from '@mui/x-data-grid-pro';

import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

import DataTable from '@/components/dataTable';
import ChipsColumn from '@/components/dataTable/customChipsColumn';
import dateRangeOperator from '@/components/dataTable/customOperators/dateRangeOperator';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useFetchPosts } from '@/hooks/queries/posts';
import { useUrlParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { getPrettyDate, formatDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute/index';
import Toolbar from '@/views/evaluations/detail/body/_partials/Toolbar';

import BooleanColumn from './BooleanColumn';

const useStyles = makeStyles()((theme) => ({
  platformChip: {
    backgroundColor: theme.palette.action.selected
  }
}));

const getProgressBarColor = (value) => {
  let red;
  let green;
  const blue = 50; // Keep blue constant to mute the color

  if (value <= 50) {
    // Muted Green to Yellow transition
    green = 200; // Start with green
    red = Math.round((value / 50) * 200); // Increase red as value increases
  } else {
    // Yellow to Muted Red transition
    red = 200; // Max out red
    green = Math.round(((100 - value) / 50) * 200); // Decrease green as value increases
  }

  return `rgb(${red}, ${green}, ${blue})`;
};

const platformMapper = {
  'instagram-post': 'IG: Post',
  'instagram-reel': 'IG: Reel',
  'instagram-story': 'IG: Story',
  tiktok: 'TT: Video',
  'youtube-short': 'YT: Short',
  'youtube-video': 'YT: Video'
};

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  compliance: undefined,
  platformDisclosure: undefined,
  tags: undefined,
  hashtags: undefined,
  platform: undefined,
  createTime: undefined,
  keywords: undefined,
  keywordGroups: undefined,
  visualRecognition: undefined,
  adType: undefined,
  isRelevant: undefined,
  isRated: undefined
};

const defaultColumnWidths = {
  adType: 130,
  compliance: 140,
  keywords: 165,
  vrDetected: 165,
  tags: 165,
  hashtags: 165,
  platform: 115,
  createTime: 140,
  creatorName: 130,
  platformDisclosure: 85,
  isRelevant: 80,
  isRated: 80,
  adRating: 100
};

const arrayToString = (array) => array.map((item) => item.keyword).join(',');

const getKeywordsObjectFrom = (originalStructure) => {
  const newArray = [];

  originalStructure.forEach((item) => {
    if (typeof item === 'object') {
      const { title: tooltip, keywords } = item;

      keywords.forEach((keyword) => {
        newArray.push({ tooltip, keyword });
      });
    } else newArray.push({ tooltip: null, keyword: item });
  });

  return newArray;
};

const getInitialFilterModelItems = (params) => {
  const {
    createTime,
    hashtags,
    platform,
    compliance,
    adType,
    isRelevant,
    isRated,
    platformDisclosure,
    tags,
    visualRecognition
  } = params;

  const items = [];

  if (compliance !== undefined)
    items.push({
      field: 'compliance',
      id: items.length,
      operator: 'isAnyOf',
      value: compliance.split(',')
    });
  if (adType !== undefined) {
    items.push({
      field: 'adType',
      id: items.length,
      operator: 'isAnyOf',
      value: adType.split(',')
    });
  }
  if (isRelevant !== undefined) {
    items.push({
      field: 'isRelevant',
      id: items.length,
      operator: 'is',
      value: isRelevant
    });
  }
  if (isRated !== undefined) {
    items.push({
      field: 'isRated',
      id: items.length,
      operator: 'is',
      value: isRelevant
    });
  }
  if (platformDisclosure !== undefined)
    items.push({
      field: 'platformDisclosure',
      id: items.length,
      operator: 'is',
      value: platformDisclosure
    });
  if (tags !== undefined)
    tags.split(',').forEach((item) =>
      items.push({
        field: 'tags',
        id: items.length,
        operator: 'contains',
        value: item
      })
    );
  if (hashtags !== undefined)
    hashtags.split(',').forEach((item) =>
      items.push({
        field: 'hashtags',
        id: items.length,
        operator: 'contains',
        value: item
      })
    );
  if (platform !== undefined)
    items.push({
      field: 'platform',
      id: items.length,
      operator: 'isAnyOf',
      value: platform.split(',')
    });
  if (visualRecognition !== undefined) {
    items.push({
      field: 'visualRecognition',
      id: items.length,
      operator: 'is',
      value: visualRecognition.split(',')
    });
  }
  if (createTime !== undefined)
    items.push({
      field: 'createTime',
      id: items.length,
      operator: 'between',
      value: createTime.split(',').map((item) => new Date(item))
    });

  return items;
};

const Results = ({ setSelectionModel, selectionModel }) => {
  const { classes } = useStyles();
  const navigate = useWorkspaceNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();
  let apiRef = useRef();
  const [isExportingCSV, setIsExportingCSV] = useState(false);

  const evaluationId = Number(useParams().evaluationId);

  const urlParams = useUrlParams(
    getRoute.evaluations.DETAIL(evaluationId),
    defaultParams
  );

  const columnWidths = useRef(defaultColumnWidths);

  const { title, requestedServices, totalItems } = queryClient.getQueryData([
    'evaluation',
    evaluationId
  ]);

  const hasVRForMinorsSelected = requestedServices?.some(
    (service) => service.type === 'minors'
  );

  const initialFilterModel = useRef({
    items: getInitialFilterModelItems(urlParams.params)
  });

  const {
    refetch,
    data: allPosts,
    isFetching: isFetchingAllPosts
  } = useFetchPosts({
    params: {
      evaluationId,
      size: totalItems.toString()
    },
    enabled: false,
    onSuccess: () => {
      if (isExportingCSV) {
        setIsExportingCSV(false);
        setTimeout(() => {
          apiRef.current.exportDataAsCsv({
            fileName: `${title}_${formatDate(
              new Date(),
              'dd-MM-yyyy_HH-mm-ss'
            )}`,
            utf8WithBom: true
          });
          alert.success('The CSV file has been downloaded!');
        }, 500);
      }
    },
    cacheTime: 0
  });

  const { data: posts, isFetching } = useFetchPosts({
    params: {
      evaluationId,
      ...urlParams.params
    },
    enabled: !!evaluationId && allPosts === undefined
  });

  const complianceMapper = useMemo(
    () => ({
      compliant: {
        icon: <CheckCircleRounded color="success" />,
        text: 'Compliant'
      },
      questionable: {
        icon: <Warning color="warning" />,
        text: 'Questionable'
      },
      violation: {
        icon: <Error color="error" />,
        text: 'Violation'
      },
      unknown: {
        icon: <></>,
        text: ''
      }
    }),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: 'adRating',
        headerName: 'Ad Score',
        type: 'number',
        width: columnWidths.current.adRating,
        filterable: false,
        visible: process.env.NODE_ENV !== 'production', // Hide in production
        sortable: true,
        renderHeader: (params) => (
          <Tooltip title="Estimate of how likely it is that this post contains advertising.">
            <Typography fontWeight="500">{params.colDef.headerName}</Typography>
          </Tooltip>
        ),
        renderCell: (params) => (
          <Stack width="100%">
            <LinearProgress
              variant="determinate"
              value={params.row.adRating * 100}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: (theme) =>
                  alpha(
                    theme.palette.grey[
                      theme.palette.mode === 'light' ? 200 : 800
                    ],
                    0.5
                  ),
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  backgroundColor: getProgressBarColor(
                    params.row.adRating * 100
                  ) // Dynamic muted color
                }
              }}
            />
          </Stack>
        )
      },
      {
        field: 'isRated',
        headerName: 'Rated',
        type: 'boolean',
        width: columnWidths.current.isRated,
        sortable: false,
        renderCell: (data) => <BooleanColumn value={data.row.isRated} />
      },
      {
        field: 'adType',
        headerName: 'Type',
        type: 'singleSelect',
        valueOptions: [
          { value: 'advertisement', label: 'Advertisement' },
          { value: 'questionable', label: 'Questionable' },
          { value: 'organic', label: 'Organic' },
          { value: 'unknown', label: 'Empty' }
        ],
        valueFormatter: (params) => params.value || '—',
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'isAnyOf'
        ),
        width: columnWidths.current.adType,
        sortable: false,
        renderCell: (data) => {
          if (data.row.adType === 'questionable') {
            return <Typography>Possible Ad</Typography>;
          }
          if (data.row.adType === '') {
            return <>—</>;
          }
          return (
            data.row.adType && (
              <Typography>
                {data.row.adType.charAt(0).toUpperCase() +
                  data.row.adType.slice(1)}
              </Typography>
            )
          );
        }
      },
      {
        field: 'compliance',
        headerName: 'Compliance',
        type: 'singleSelect',
        valueOptions: [
          { value: 'compliant', label: 'Compliant' },
          { value: 'questionable', label: 'Questionable' },
          { value: 'violation', label: 'Violation' },
          { value: 'unknown', label: 'Empty' }
        ],
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'isAnyOf'
        ),
        valueFormatter: (params) => params.value || '—',
        width: columnWidths.current.compliance,
        sortable: false,
        renderCell: (data) => {
          if (
            data.row.adType === 'organic' ||
            data.row.isRelevant === false ||
            data.row.compliance === 'unknown'
          ) {
            return <>—</>;
          }

          return (
            <Stack direction="row" alignItems="center" gap="4px">
              {complianceMapper[data.row.compliance]?.icon}
              <Typography>
                {complianceMapper[data.row.compliance]?.text}
              </Typography>
            </Stack>
          );
        }
      },
      {
        field: 'isRelevant',
        headerName: 'Relevant',
        type: 'boolean',
        width: columnWidths.current.isRelevant,
        sortable: false,
        valueFormatter: (params) => params.value || '—',
        renderCell: (data) => {
          if (data.row.isRelevant === undefined) {
            return <>—</>;
          }
          return <BooleanColumn value={data.row.isRelevant ?? null} />;
        }
      },
      {
        field: 'platform',
        headerName: 'Platform',
        type: 'singleSelect',
        valueOptions: Object.keys(platformMapper).map((key) => ({
          value: key,
          label: platformMapper[key]
        })),
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'isAnyOf'
        ),
        width: columnWidths.current.platform,
        sortable: false,
        renderCell: (data) =>
          data.row.platform &&
          (data.row.url ? (
            <Chip
              onClick={() =>
                data.row.url && window.open(data.row.url, '_blank')
              }
              className={classes.platformChip}
              label={platformMapper[data.row.platform]}
              sx={{ cursor: 'pointer' }}
            />
          ) : (
            <Tooltip title="Link unavailable" arrow>
              <Chip
                className={classes.platformChip}
                label={platformMapper[data.row.platform]}
                sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
          ))
      },
      {
        field: 'creatorName',
        headerName: 'Creator',
        width: columnWidths.current.creatorName,
        filterable: false,
        sortable: false,
        renderCell: (data) =>
          data.row.creatorId === null ? (
            <Tooltip title="This creator has been deleted">
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <WarningAmber color="warning" fontSize="small" />
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap', // Ensure single line with ellipsis
                    flexShrink: 1, // Allow shrinking of the text to fit the space
                    minWidth: 0 // Prevent the element from exceeding container width
                  }}
                  id="creatorName"
                  color="error"
                >
                  {data.row.creatorName}
                </Typography>
              </Stack>
            </Tooltip>
          ) : (
            <Tooltip title={data.row.creatorName}>
              <Link
                component={RouterLink}
                onClick={(event) => {
                  event.preventDefault(); // Prevent default link behavior
                  navigate(getRoute.creators.DETAIL(data.row.creatorId));
                }}
                color="primary"
                style={{ width: '100%' }} // Ensure link container uses full width
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap', // Ensure single line with ellipsis
                    flexShrink: 1, // Allow shrinking of the text to fit the space
                    minWidth: 0 // Prevent the element from exceeding container width
                  }}
                  id="creatorName"
                >
                  {data.row.creatorName}
                </Typography>
              </Link>
            </Tooltip>
          )
      },
      {
        field: 'platformDisclosure',
        headerName: 'Sponsorship',
        type: 'boolean',
        width: columnWidths.current.platformDisclosure,
        sortable: false,
        renderCell: (data) => (
          <BooleanColumn value={data.row.platformDisclosure.length > 0} />
        )
      },
      {
        field: 'keywords',
        headerName: 'Keywords',
        filterable: false,
        width: columnWidths.current.keywords,
        sortable: false,
        valueFormatter: (params) => arrayToString(params.value),
        renderCell: (data) => (
          <ChipsColumn
            data={data.row.keywords}
            chipColor="opacity75"
            cellWidth={data.colDef.computedWidth}
          />
        )
      },
      {
        field: 'visualRecognition',
        headerName: 'VR detected',
        type: 'singleSelect',
        valueOptions: [
          { value: '0-17', label: 'Age 0-17' },
          { value: '0-6', label: 'Age 0-6' },
          { value: '7-14', label: 'Age 7-14' },
          { value: '15-17', label: 'Age 15-17' }
        ],
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'is'
        ),
        filterable: hasVRForMinorsSelected,
        visible: hasVRForMinorsSelected,
        width: columnWidths.current.vrDetected,
        sortable: false,
        valueFormatter: (params) => arrayToString(params.value),
        renderCell: (data) => (
          <ChipsColumn
            data={data.row.visualRecognition}
            chipColor="opacity75"
            cellWidth={data.colDef.computedWidth}
          />
        )
      },
      {
        field: 'tags',
        headerName: 'Tags',
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        width: columnWidths.current.tags,
        sortable: false,
        valueFormatter: (params) => arrayToString(params.value),
        renderCell: (data) => (
          <ChipsColumn
            data={data.row.tags}
            chipColor="secondary"
            cellWidth={data.colDef.computedWidth}
          />
        )
      },
      {
        field: 'hashtags',
        headerName: 'Hashtags',
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        width: columnWidths.current.hashtags,
        sortable: false,
        valueFormatter: (params) => arrayToString(params.value),
        renderCell: (data) => (
          <ChipsColumn
            data={data.row.hashtags}
            chipColor="secondary"
            cellWidth={data.colDef.computedWidth}
          />
        )
      },
      {
        field: 'createTime',
        headerName: 'Publication Date',
        type: 'date',
        filterOperators: dateRangeOperator,
        width: columnWidths.current.createTime,
        valueFormatter: (params) => String(params.value),
        renderCell: (data) => (
          <Typography variant="body2">
            {getPrettyDate(data.row.createTime)}
          </Typography>
        )
      }
    ],
    [posts, columnWidths.current]
  );

  const onColumnResize = useCallback(({ colDef }) => {
    const { field, width } = colDef;
    columnWidths.current = { ...columnWidths.current, [field]: width };
  }, []);

  const onChangePageSize = useCallback(
    (newSize) => {
      urlParams.updateParams({ size: newSize });
    },
    [urlParams]
  );

  const onChangePage = useCallback(
    (newPage) => {
      urlParams.updateParams({ page: newPage });
    },
    [urlParams]
  );

  const onSortModelChange = useCallback(
    (params) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      urlParams.updateParams({ sort: newSort, order: newOrder });
    },
    [urlParams]
  );

  const onFilterModelChange = useCallback(
    (params) => {
      const newParams = {};

      // adds active filters to the newParams object
      params.items.forEach((item) => {
        const { field, value } = item;

        if (field === 'createTime' && value !== undefined) {
          const formattedDates = value.map((date) =>
            formatDate(date, 'yyyy-MM-dd')
          );
          const isSomeDateDefined = formattedDates.some(
            (date) => date !== undefined
          );

          newParams.createTime = isSomeDateDefined
            ? formattedDates.join(',')
            : undefined;
        } else if (typeof value === 'object') {
          newParams[field] = value.join(',');
        } else if (field === 'hashtags' || field === 'tags') {
          newParams[field] = newParams[field]
            ? `${newParams[field]},${value}`
            : value;
        } else {
          newParams[field] = value;
        }
      });

      // sets removed filters as undefined so that they get deleted from the url
      columns
        .filter((column) => column.filterable !== false)
        .forEach((column) => {
          const filterExistsInTheUrl = Object.keys(
            urlParams.paramsInTheUrl
          ).includes(column.field);
          const isFilterActive = params.items.some(
            (item) => item.field === column.field
          );
          if (filterExistsInTheUrl && !isFilterActive)
            newParams[column.field] = undefined;
        });

      urlParams.updateParams({
        ...urlParams.paramsInTheUrl,
        ...newParams,
        page: DEFAULT_PAGE
      });
    },
    [urlParams]
  );

  const { sort, order, page, size } = urlParams.params;
  const mode = allPosts?.results.length > 0 ? 'client' : 'server';

  const formattedData = useMemo(
    () =>
      (mode === 'client' ? allPosts : posts)?.results.map((post) => ({
        creatorId: post?.creator?.id,
        creatorName: post?.creator?.name,
        createTime: post.createTime,
        hashtags: post.hashtags.map((hashtags) => ({
          keyword: `#${hashtags}`,
          tooltip: ''
        })),
        id: post.id,
        url: post.url,
        keywords: [
          ...getKeywordsObjectFrom(post.evaluation.keywords.must),
          ...getKeywordsObjectFrom(post.evaluation.keywords.might),
          ...getKeywordsObjectFrom(post.evaluation.keywords.some),
          ...getKeywordsObjectFrom(post.evaluation.keywordGroups.must),
          ...getKeywordsObjectFrom(post.evaluation.keywordGroups.might),
          ...getKeywordsObjectFrom(post.evaluation.keywordGroups.some)
        ],
        platform: post.platform,
        compliance: post.evaluation.compliance,
        adType: post.evaluation.adType,
        isRelevant: post.evaluation.isRelevant,
        isRated: post.evaluation.isRated,
        platformDisclosure: post.platformDisclosure,
        tags: post.tags.map((tags) => ({
          keyword: `@${tags.username}`,
          tooltip: ''
        })),
        adRating: post.adRating,
        visualRecognition: post.visualRecognition?.map((service) => {
          const intervals = [
            { min: 0, max: 6 },
            { min: 7, max: 14 },
            { min: 15, max: 17 }
          ];

          const intervalsToShow = intervals
            .filter((interval) =>
              service.predictedAges.some(
                (average) => interval.min <= average && average <= interval.max
              )
            )
            .map((interval) => ({
              keyword: `Age ${interval.min}-${interval.max}`,
              tooltip: 'Children'
            }));

          return intervalsToShow[0] || [];
        })
      })),
    [allPosts, posts]
  );

  return (
    <Box mt={2}>
      <DataTable
        checkboxSelection
        columns={columns}
        filterMode={mode}
        paginationMode={mode}
        sortingMode={mode}
        initialFilterModel={initialFilterModel.current}
        loading={isFetchingAllPosts || isFetching}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onColumnResize={onColumnResize}
        onFilterModelChange={onFilterModelChange}
        onRowSelectionModelChange={setSelectionModel}
        onSortModelChange={onSortModelChange}
        keepNonExistentRowsSelected={mode === 'server'}
        order={order}
        page={page}
        rowCount={mode === 'client' ? allPosts?.totalItems : posts?.totalItems}
        rowHeight={56}
        rows={formattedData || []}
        rowSelectionModel={selectionModel}
        size={size}
        sort={sort}
        multipleFiltersColumns={['tags', 'hashtags']}
        onRowClick={(data, event) => {
          if (
            event.target.id !== 'creatorName' &&
            !!data.row.platform &&
            !event.target.className.includes('MuiChip')
          ) {
            const newParams = { ...urlParams.params };
            delete newParams.page;
            delete newParams.size;

            navigate(
              getRoute.evaluations.POST(evaluationId, data.id, newParams)
            );
          }
        }}
        toolbar={() => (
          <Toolbar
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            refetch={refetch}
            setIsExportingCSV={setIsExportingCSV}
            onChangeApiRef={(ref) => {
              apiRef = ref;
            }}
          />
        )}
        columnVisibilityModel={{
          visualRecognition: hasVRForMinorsSelected,
          adRating: process.env.NODE_ENV !== 'production' // Hide in production
        }}
      />
    </Box>
  );
};

Results.propTypes = {
  selectionModel: PropTypes.array,
  setSelectionModel: PropTypes.func
};

export default Results;
